import type { ButtonProps, Theme, TypeText } from '@mui/material';

export type ColorWithText = ButtonProps['color'] | `text_${keyof TypeText}`;

export const isTextColor = (color: Exclude<ColorWithText, undefined>): color is `text_${keyof TypeText}` =>
  color.includes('text');

export const getTextColor = (color: Exclude<ColorWithText, undefined>): keyof TypeText =>
  color.split('_')[1] as keyof TypeText;

export const getColorFromTheme = (color: Exclude<ColorWithText, undefined>) => (theme: Theme) => {
  if (isTextColor(color)) {
    return theme.palette.text[getTextColor(color)];
  }
  if (color === 'inherit') {
    return 'inherit';
  }
  return theme.palette[color].main;
};
