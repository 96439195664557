import {
  ToggleButtonGroup,
  ToggleButton as MuiToggleButton,
  styled,
  toggleButtonClasses,
  debounce,
} from '@mui/material';
import { sub, endOfToday } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import DateRangePickerModal, { type DateRange } from './DateRangePickerModal';

type TimeframeProps = {
  onSelect: (value: DateRange) => void;
  value: DateRange;
  disabled?: boolean;
};

export type TimeFrameOption = {
  label: string;
  shorthand: string | undefined;
  value: DateRange;
};

const now = endOfToday();

export const AVAILABLE_TIME_FRAMES: TimeFrameOption[] = [
  ...[3, 6, 9, 12].map(months => ({
    shorthand: `${months} m`,
    label: `${months} months`,
    value: {
      from: sub(now, { months }),
      to: now,
    },
  })),
  { label: 'all', shorthand: undefined, value: { from: new Date(0), to: now } },
];

const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
  [`&.${toggleButtonClasses.root}`]: {
    textTransform: 'unset',
    borderRadius: '100vw',
    backgroundColor: 'transparent',
    ...theme.typography.labelLarge,
  },
  [`&.${toggleButtonClasses.selected}`]: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
}));

const SelectTimeframe = ({ value: currentValue, onSelect, disabled = false }: TimeframeProps) => {
  const [custom, setCustom] = useState(false);
  const [shorthands, setShorthands] = useState(false);
  const groupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const check = () => {
      if (!groupRef.current) {
        return;
      }
      setShorthands(groupRef.current.offsetWidth <= 650);
    };
    check();
    const shouldUseShorthands = debounce(check, 50);
    window.addEventListener('resize', shouldUseShorthands);
    return () => window.removeEventListener('resize', shouldUseShorthands);
  }, []);

  const handleSelectedChange = (_e: React.MouseEvent<HTMLElement>, v: DateRange | null) => {
    if (v === null) {
      return;
    }
    onSelect(v);
    setCustom(false);
  };
  const handleCustomChange = (v: DateRange) => {
    setCustom(true);
    onSelect(v);
  };
  return (
    <div ref={groupRef} style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
      <ToggleButtonGroup value={currentValue} onChange={handleSelectedChange} exclusive disabled={disabled}>
        {AVAILABLE_TIME_FRAMES.map(({ value, label, shorthand }) => (
          <ToggleButton key={label} value={value}>
            {shorthands && !!shorthand ? shorthand : label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <DateRangePickerModal onChange={handleCustomChange} value={currentValue} selected={custom} disabled={disabled} />
    </div>
  );
};

export default SelectTimeframe;
