import { useRef } from 'react';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import { useUserData } from 'providers/user';
import { ContentLoader } from 'components/Layout/ContentLoader';
import { useNominate } from 'api/hooks/nominations/useNominate';
import { FormContainerRef } from 'components/Form/FormContainer';
import { CallToAction, type CounterInfo } from 'components/CTA';
import { type Theme } from '@mui/material/styles';
import { Highlight } from 'components/Layout/Highlight';
import { useMediaQuery, Typography } from '@mui/material';
import { ReactComponent as NominateIcon } from 'assets/A4BEE-volounteer.svg';
import { useGetCurrentVotingStatistics, useGetPreviousVotingStatistics } from 'api/hooks/statistics';
import { BotMError } from 'api/controllers/typings';
import { NominateForm } from './Form/NominateForm';
import { ValuesDescription } from './ValuesDescription';

const ctaPoints = [
  <>
    You can nominate as many people as you want, <br />
    <strong>there is no limit</strong> to the chance of appreciating someone!
  </>,
  <>
    Every nomination is important and <strong>we want you to tell us why!</strong> <br />
    That&apos;s why we give you a lot of letters for it, up to 100-200!
  </>,
  <>
    We nominate for the first week of each month. <br />
    <strong> Hurry, we&apos;re voting soon! </strong>
  </>,
  <>
    Be sure to come back next week to <strong>vote for the potential winner!</strong>
  </>,
];

const makeNominationCounter = (
  thisMonthNominations: number | undefined,
  pastMonthNominations: number | undefined,
): CounterInfo => ({
  Icon: NominateIcon,
  number: thisMonthNominations ?? null,
  tooltip: pastMonthNominations
    ? {
        position: 'relative',
        arrow: 'left',
        content: `Let's see if we can beat ${pastMonthNominations},
        last month score!`,
      }
    : undefined,
});

export const NominatePage: React.FC = (): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const { users } = useUserData();
  const formRef = useRef<FormContainerRef | null>(null);
  const { data: thisMonthStats } = useGetCurrentVotingStatistics();
  const { data: pastMonthStats } = useGetPreviousVotingStatistics();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const { mutate: addNomination, isLoading } = useNominate({
    onSuccess: () => {
      enqueueSnackbar('Your nomination has been added', {
        variant: 'success',
      });
      formRef.current?.reset();
    },
    onError: err => {
      const detail: BotMError['detail'] | undefined = err.response?.data?.detail;
      if (detail) {
        const msg =
          typeof detail === 'string' ? detail : detail.map(det => (typeof det === 'string' ? det : det.msg)).join('; ');
        enqueueSnackbar(msg, { variant: 'error' });
      } else {
        enqueueSnackbar('Oops... something went wrong', { variant: 'error' });
      }
    },
  });
  return (
    <Box
      sx={{
        pt: 8,
        pb: 6,
      }}>
      <CallToAction
        columns='minmax(0, 4fr) minmax(0, 6fr)'
        gap='2em'
        points={ctaPoints}
        title={
          <Typography variant={isSmall ? 'displaySmall' : 'displayMedium'} fontWeight='700'>
            Nominations so far this month! <Highlight text='Join&nbsp;us!' />
          </Typography>
        }
        counters={[makeNominationCounter(thisMonthStats?.num_of_nominations, pastMonthStats?.num_of_nominations)]}
      />
      <ContentLoader isLoaded={(users?.length ?? 0) > 0}>
        <NominateForm onSubmit={addNomination} isLoading={isLoading} ref={formRef} />
      </ContentLoader>
      <ValuesDescription />
    </Box>
  );
};
