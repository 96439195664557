import { ThemeOptions } from '@mui/material/styles';
import { percentToHex } from 'utils/numberConversion';
import { typographyVariants } from './typography';
import { neutral, neutralVariant } from './colors';

const sideDistance = (2 - Math.sqrt(3)) * 25; // distance from side of a hexagon to a side of a square with equal heights (in %)
const customRadius = {
  borderRadius: 0,
};

const overrideBorderRadius = {
  styleOverrides: {
    root: customRadius,
  },
};

const overrideBorderRadiusBasedOnState = <T>(check: (state: T) => boolean) => ({
  styleOverrides: {
    root: ({ ownerState }: { ownerState: T }) => ({
      ...(check(ownerState) && customRadius),
    }),
  },
});

export const common: Partial<ThemeOptions> = {
  typography: {
    fontFamily: 'Montserrat, sans serif',
    ...typographyVariants,
  },
  breakpoints: {
    values: {
      xxs: 0,
      xs: 500,
      sm: 700,
      md: 900,
      lg: 1280,
      xl: 1536,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: theme => ({
        body: {
          scrollbarColor: `${theme.palette.success.main} ${neutralVariant[theme.palette.mode === 'dark' ? 30 : 80]}`,
          scrollbarWidth: '8px',
          ' *::-webkit-scrollbar': {
            height: '5px',
            width: '5px',
            '&-thumb': {
              background: theme.palette.success.main,
              borderRadius: '100vmax',
            },
            '&-track': {
              backgroundColor: neutralVariant[theme.palette.mode === 'dark' ? 30 : 80],
              borderRadius: '100vmax',
            },
            '&-corner': {
              background: 'transparent',
            },
          },
        },
      }),
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...customRadius,
          ...typographyVariants.labelLarge,
          textTransform: 'inherit',
          boxShadow: 'none',
          padding: '6px 13px',
        },
        outlined: {
          padding: '5px 12px',
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiCard: overrideBorderRadius,
    MuiListItem: overrideBorderRadius,
    MuiMenu: overrideBorderRadius,
    MuiPaper: overrideBorderRadius,
    MuiDialog: overrideBorderRadius,
    MuiInputBase: overrideBorderRadius,
    MuiInput: overrideBorderRadius,
    MuiSelect: overrideBorderRadius as any, // without cast doesn't work
    MuiChip: overrideBorderRadiusBasedOnState(state => state.avatar === undefined),
    MuiTooltip: {
      styleOverrides: {
        popper: ({ theme: { palette }, ownerState: { color } }) =>
          color && {
            backgroundColor: palette[color]?.main,
            color: palette[color]?.contrastText,
          },
        arrow: ({ theme: { palette }, ownerState: { color } }) =>
          color && {
            backgroundColor: palette[color]?.main,
          },
      },
    },
    MuiAvatar: {
      variants: [
        {
          props: { variant: 'hex' },
          style: {
            clipPath: `polygon( ${sideDistance}% 25%, 50% 0, ${100 - sideDistance}% 25%, ${
              100 - sideDistance
            }% 75%, 50% 100%, ${sideDistance}% 75%)`,
          },
        },
      ],
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          ...typographyVariants.titleMedium,
          paddingTop: '12px',
          paddingBottom: '12px',
        },
      },
    },
  },
};
export const commonPalette = {
  neutral,
  neutralVariant,
};

export const gradients = (defaultColor = neutralVariant[95]) => ({
  top: ({ color = defaultColor, startOpacity = 100, end = '100%' } = {}) =>
    `linear-gradient(to top, ${color}${percentToHex(startOpacity)} 0%, ${color}00 ${end});`,
  bottom: ({ color = defaultColor, startOpacity = 100, end = '100%' } = {}) =>
    `linear-gradient(to bottom, ${color}${percentToHex(startOpacity)} 0%, ${color}00 ${end});`,
  bottomLeft: ({ color = defaultColor, startOpacity = 100, end = '100%' } = {}) =>
    `linear-gradient(to bottom left, ${color}${percentToHex(startOpacity)} 0%, ${color}00 ${end});`,
});

export type BlendSteps = 1 | 2 | 3 | 4 | 5;

export type Blend = {
  background: string;
  backgroundBlendMode: string;
};

const surfaceOpacities: {
  [key in BlendSteps]: number;
} = {
  1: 5,
  2: 8,
  3: 11,
  4: 12,
  5: 14,
};

export type BlendFC = (step: BlendSteps, bgColor?: string) => Blend;

export type BlendOptions = {
  blendColor: string;
  defaultBackground: string;
};

export const blends: (opt: BlendOptions) => BlendFC =
  ({ blendColor, defaultBackground }) =>
  (step, backgroundColor = defaultBackground) => ({
    background: `linear-gradient(${backgroundColor}, ${backgroundColor}), linear-gradient(${blendColor}${percentToHex(
      surfaceOpacities[step],
    )}, ${blendColor}${percentToHex(surfaceOpacities[step])})`,
    backgroundBlendMode: 'multiply',
  });
