import { Client } from '../client';
import type {
  CurrentVotingStatisticsResponse,
  NominationsReceivedStat,
  NominationsSentStat,
  PreviousVotingStatisticsResponse,
  StatsParams,
  UserStat,
  VotesCastStat,
} from './typings';

class StatisticsController {
  static client = Client;

  static getCurrentVotingStatistics(): Promise<CurrentVotingStatisticsResponse> {
    return this.client.get('/votings/current/stats');
  }

  static getPreviousVotingStatistics(): Promise<PreviousVotingStatisticsResponse> {
    return this.client.get('/votings/previous/stats');
  }

  static getNominationsSentStats(params: StatsParams) {
    return this.#getStats<NominationsSentStat>('/nominations/sent/most/stats', params);
  }

  static getNominationsReceivedStats(params: StatsParams) {
    return this.#getStats<NominationsReceivedStat>('/nominations/received/most/stats', params);
  }

  static getVotesCastStats(params: StatsParams) {
    return this.#getStats<VotesCastStat>('/votes/casts/most/stats', params);
  }

  static #getStats<UserType extends UserStat>(url: string, { from, to, limit }: StatsParams): Promise<UserType[]> {
    const params = {
      from_date_millis: from.getTime(),
      to_date_millis: to.getTime(),
      limit,
    };
    return this.client.get(url, { params });
  }
}

export { StatisticsController };
