import MuiDialog from '@mui/material/Dialog';
import { Avatar, Box, Button, DialogActions, DialogContent, DialogTitle, Typography, capitalize } from '@mui/material';
import { withClose } from 'components/drawers/withClose';
import CloseIcon from '@mui/icons-material/Close';
import { PersistantTooltip } from 'components/PersistantTooltip/TopdownTooltip';
import { format } from 'date-fns';
import { DateTypography, NominationTileProps } from './NominationTile';

interface NominationDialogProps extends NominationTileProps {
  open: boolean;
  close: () => void;
}

const Dialog = withClose(MuiDialog);

export const NominationDialog = ({ open, close, ...props }: NominationDialogProps) => {
  return (
    <Dialog open={open} onClose={close} sx={{ cursor: 'default', wordWrap: 'break-word' }}>
      <DialogTitle>
        <Box display='flex' justifyContent='flex-start' alignItems='center' gap='10px'>
          <Avatar src={props.avatarImage} variant='hex' sx={{ height: '80px', width: '80px' }} />
          <Box display='flex' flexDirection='column'>
            {props.firstName && props.lastName && (
              <Typography variant='labelLarge'>
                {capitalize(props.firstName)} {props.lastName[0].toLocaleUpperCase()}.
              </Typography>
            )}
            <DateTypography>{format(props.date, 'dd.MM.yy')}</DateTypography>
          </Box>
          <PersistantTooltip variant='labelLarge' arrow='left' position='relative'>
            {props.value}
          </PersistantTooltip>
        </Box>
      </DialogTitle>
      <DialogContent>{props.comment?.replace(/\.{4,}$/, '.')}</DialogContent>
      <DialogActions>
        <Button onClick={close} startIcon={<CloseIcon />} variant='contained' sx={{ boxShadow: 'none' }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
