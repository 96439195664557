import { NominationsReceivedStat, NominationsSentStat, VotesCastStat } from 'api/controllers/typings';
import { useMemo } from 'react';

const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export function useCSVDownload(csvRows: string[] | undefined, header?: string) {
  return useMemo(() => {
    if (!csvRows) {
      return '';
    }
    const start = header ? `${header}\n` : '';
    const file = start.concat(csvRows.join('\n'));
    const type = isSafari() ? 'application/csv' : 'text/csv';
    const blob = new Blob([file], { type });
    return URL.createObjectURL(blob);
  }, [csvRows, header]);
}

type MaybeStats<T> = T[] | undefined;

export function useConvertToCSV(
  nominationsSent: MaybeStats<NominationsSentStat>,
  nominationsReceived: MaybeStats<NominationsReceivedStat>,
  votesCast: MaybeStats<VotesCastStat>,
) {
  return useMemo(() => {
    if (nominationsSent === undefined || nominationsReceived === undefined || votesCast === undefined) {
      return undefined;
    }
    const convertedData: string[] = [];
    nominationsSent.forEach(stat => {
      convertedData.push(`${stat.nominator_email};NOMINATIONS SENT;${stat.number_of_nominations}`);
    });
    nominationsReceived.forEach(stat => {
      convertedData.push(`${stat.nominee_email};NOMINATIONS REVEIVED;${stat.number_of_nominations}`);
    });
    votesCast.forEach(stat => {
      convertedData.push(`${stat.voter_email};VOTES CAST;${stat.number_of_votes}`);
    });
    return convertedData;
  }, [nominationsReceived, nominationsSent, votesCast]);
}
