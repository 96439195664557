import LoadingButton from '@mui/lab/LoadingButton';
import { Typography, Container, Input, FormControl, FormHelperText, InputLabel, Box } from '@mui/material';
import { GradientSection } from 'components/Layout/GradientSection';
import { format } from 'date-fns';
import { ChangeEventHandler, useState, useTransition } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import SelectTimeframe, { AVAILABLE_TIME_FRAMES } from './SelectTimeframe';
import { useConvertToCSV, useCSVDownload } from './useCSVDownload';
import { useReportData } from './useReportData';
import { DateRange } from './DateRangePickerModal';

const headers = 'Osoba;Kategoria;Wynik';

const ReportSection = () => {
  const [timeframe, setTimeframe] = useState(AVAILABLE_TIME_FRAMES[0].value);
  const [isPending, startTransition] = useTransition();
  const [limit, setLimit] = useState(50);
  const [limitError, setLimitError] = useState<string>();
  const { disabled, loading, votesCast, nominationsSent, nominationsReceived } = useReportData(timeframe, limit);
  const csvData = useConvertToCSV(nominationsSent, nominationsReceived, votesCast);
  const downloadURI = useCSVDownload(csvData, headers);
  const filename = `report_top${limit}-${format(timeframe.from, 'yyyy:MM:dd')}-${format(
    timeframe.from,
    'yyyy:MM:dd',
  )}.csv`;

  const changeLimit: ChangeEventHandler<HTMLInputElement> = e => {
    if (!e.target.value) {
      setLimitError('Limit has to be provided!');
      return;
    }
    if (e.target.validity.patternMismatch) {
      setLimitError('Limit should be a non-negative number!');
      return;
    }
    setLimitError(undefined);
    startTransition(() => setLimit(+e.target.value));
  };

  const selectTimeframe = (dateRange: DateRange) => startTransition(() => setTimeframe(dateRange));

  return (
    <GradientSection>
      <Container
        sx={{
          pt: 8,
          pb: 6,
        }}>
        <Typography variant='headlineLarge' color='text.primary'>
          Report
        </Typography>
        <Box
          pt={3}
          display='flex'
          flexDirection={{ xxs: 'column', md: 'row' }}
          gap={{ xxs: '20px', md: '10px' }}
          alignItems='baseline'>
          <SelectTimeframe value={timeframe} onSelect={selectTimeframe} />
          <FormControl error={limitError !== undefined} variant='standard'>
            <InputLabel htmlFor='limit-input'>Top X</InputLabel>
            <Input
              inputProps={{
                min: 0,
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
              defaultValue={limit}
              onChange={changeLimit}
              id='limit-input'
              aria-describedby='limit-input-text'
            />
            {limitError && <FormHelperText id='limit-input-text'>{limitError}</FormHelperText>}
          </FormControl>
          <LoadingButton
            href={downloadURI}
            download={filename}
            target='_self'
            sx={{ ml: 2 }}
            variant='contained'
            startIcon={<SaveIcon />}
            loading={loading || isPending}
            disabled={disabled}>
            Generate a report
          </LoadingButton>
        </Box>
      </Container>
    </GradientSection>
  );
};

export default ReportSection;
