import { type Theme, type CSSObject, styled } from '@mui/material/styles';
import { HTMLAttributes } from 'react';
import { withCutCorner } from './cutCorner';

export type SectionBoxProps = {
  muiStyling?: CSSObject | ((theme: Theme) => CSSObject);
  sectionContainerProps?: HTMLAttributes<HTMLDivElement>;
  pad?: boolean;
  variant?: 'filled' | 'bordered';
} & HTMLAttributes<HTMLDivElement>;

const BORDER_WIDTH = 2;

const SectionBoxContainer = withCutCorner(
  styled(({ children, ...props }: SectionBoxProps) => <div {...props}>{children}</div>, {
    shouldForwardProp: name => name !== 'muiStyling' && name !== 'variant',
  })(({ theme, muiStyling, variant = 'filled' }) => ({
    ...(variant === 'filled'
      ? {
          background: theme.palette.background.paperVariant,
        }
      : {
          border: `${BORDER_WIDTH}px solid ${theme.palette.background.paperVariant}`,
          '&::after': {
            position: 'absolute',
            content: '""',
            background: theme.palette.background.paperVariant,
            width: 'calc(var(--corner) * 1.41)',
            height: BORDER_WIDTH,
            top: -1 * BORDER_WIDTH,
            right: `calc(var(--corner) * -0.41 - ${BORDER_WIDTH}px)`,
            transformOrigin: '0 0',
            rotate: '45deg',
          },
        }),
    position: 'relative',
    padding: '20px 30px',
    '& ~ .dash': {
      width: 32,
      height: 13,
      position: 'absolute',
      top: 24,
      left: 0,
      transform: 'translateX(-50%)',
      backgroundColor: theme.palette.primary.main,
    },
    ...(typeof muiStyling === 'function' ? muiStyling?.(theme) : muiStyling),
  })),
);

export const SectionBox = ({
  children,
  sectionContainerProps,
  muiStyling,
  style,
  pad,
  variant,
  ...props
}: SectionBoxProps) => {
  return (
    <div style={{ position: 'relative', ...style, paddingLeft: pad ? '16px' : undefined }} {...props}>
      <SectionBoxContainer
        {...sectionContainerProps}
        muiStyling={muiStyling}
        variant={variant}
        cornerSize='20px'
        corner='TR'>
        {children}
      </SectionBoxContainer>
      <div className='dash' style={pad ? { left: '16px' } : undefined} />
    </div>
  );
};
