import MuiDrawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DarkModeSwitch } from 'components/DarkModeSwitch';
import { useDrawerContext } from 'providers/drawers/provider';
import { MenuButtons } from '../Layout/Menu/MenuButtons';
import { BeeButton } from '../Layout/Header/BeeButton';
import { UserGreeting } from '../Layout/Header/UserGreeting';
import { withClose } from './withClose';

const hrStyle = {
  width: '100%',
  margin: '0',
};
const Drawer = withClose(MuiDrawer);

export const MobileDrawer = () => {
  const {
    state: {
      menu: { isOpen, close },
    },
  } = useDrawerContext();
  return (
    <Drawer
      open={isOpen}
      onClose={close}
      PaperProps={{
        sx: {
          bgcolor: 'primary.main',
          color: 'primary.contrastText',
          backgroundImage: 'none',
          width: { xs: '300px', xxs: '100%' },
        },
      }}>
      <Box p='40px' pt='0' display='flex' flexDirection='column' alignItems='flex-start' gap={5} height='100%'>
        <BeeButton onClick={close} />
        <UserGreeting />
        <hr style={hrStyle} />
        <MenuButtons
          type='textWithIcon'
          onClickAny={close}
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          alignItems='flex-start'
          height='100%'
          buttonProps={{ sx: { typography: 'headlineLarge' } }}
        />
        <hr style={hrStyle} />
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant='titleMedium'>Dark mode</Typography>
          <DarkModeSwitch />
        </Box>
      </Box>
    </Drawer>
  );
};
