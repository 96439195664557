import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { Box, Button, Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useGetCurrentVotingStatistics } from 'api/hooks/statistics';
import { SectionBox } from 'components/Layout/SectionBox';
import { Link } from 'react-router-dom';
import { Statistic, StatProps } from './Statistic';

const changeLayoutFrom = 'sm';

const styles = ({ breakpoints }: Theme) => ({
  display: 'grid',
  gap: '20px',
  [breakpoints.up(changeLayoutFrom)]: {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  gridTemplateColumns: 'repeat(3, 1fr)',
  [breakpoints.down(425)]: {
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'flex-end',
    justifyItems: 'start',
    '&> *:where(:first-child, :last-child)': {
      gridColumn: 'span 2',
    },
  },
  alignItems: 'stretch',
  justifyItems: 'center',
});

const getEngagementColor = (e: number | undefined): StatProps['color'] => {
  if (!e) {
    return undefined;
  }
  if (e < 0) {
    return 'error';
  }
  return 'success';
};

function formatEngagement(engagement: number | undefined): string | undefined {
  if (engagement === undefined) {
    return undefined;
  }
  const sign = engagement < 0 ? '' : '+';
  return `${sign}${engagement.toFixed(0)}%`;
}

export const StatisticsSummary = () => {
  const { data } = useGetCurrentVotingStatistics();
  return (
    <SectionBox variant='bordered' pad muiStyling={styles}>
      <Box
        gridColumn={{ xxs: 'span 3', [changeLayoutFrom]: 'span 1' }}
        display='flex'
        flexDirection={{ xxs: 'row', [changeLayoutFrom]: 'column' }}
        width={{ xxs: '100%', [changeLayoutFrom]: 'unset' }}
        justifyContent='space-between'
        alignItems='flex-start'
        pr='10px'>
        <Typography variant='titleSmall'>Stats this month</Typography>
        <Button to='/statistics' component={Link} startIcon={<QueryStatsIcon />} variant='outlined' color='contrast'>
          All stats
        </Button>
      </Box>
      <Statistic data={data?.num_of_nominations} tooltipText='Nominations' />
      <Statistic data={data?.num_of_votes} tooltipText='Votes' />
      <Statistic
        data={formatEngagement(data?.engagement)}
        tooltipText='Engagement'
        color={getEngagementColor(data?.engagement)}
      />
    </SectionBox>
  );
};
