import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { DateCalendar, DateCalendarProps } from '@mui/x-date-pickers/DateCalendar';
import { useMemo, useState } from 'react';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import { FilledIconButton } from 'components/FilledIconButton';

export type DateRange = {
  from: Date;
  to: Date;
};

type DateRangePickerModalProps = {
  value: DateRange;
  onChange: (value: DateRange) => void;
  selected: boolean;
  disabled: boolean;
};
const defaultDateRange = {
  from: new Date(),
  to: new Date(),
};

type Stage = 'none' | 'from' | 'to';

const DatePickerModal = ({ title, onClose, open, ...calendarProps }: DatePickerModalProps) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DateCalendar showDaysOutsideCurrentMonth view='day' {...calendarProps} />
    </DialogContent>
  </Dialog>
);

const makeModalProps = {
  from: {
    title: 'Choose start date',
    disableFuture: true,
  },
  to: {
    title: 'Choose end date',
    disableFuture: true,
  },
} satisfies Record<'from' | 'to', Partial<DatePickerModalProps>>;

export const DateRangePickerModal = ({ value, onChange, selected, disabled }: DateRangePickerModalProps) => {
  const [stage, setStage] = useState<Stage>('none');
  const [innerValue, setInnerValue] = useState<DateRange>(value);

  const startPicking = () => setStage('from');
  const resetModals = () => {
    setStage('none');
    setInnerValue(value);
  };
  const inner = useMemo(
    () => ({
      from: (date: Date) => {
        setStage('to');
        setInnerValue(prev => ({
          ...prev,
          from: date,
        }));
      },
      to: (date: Date) => {
        setStage('none');
        onChange({
          ...innerValue,
          to: date,
        });
        setInnerValue(defaultDateRange);
      },
    }),
    [],
  );
  return (
    <>
      <FilledIconButton onClick={startPicking} color={selected ? 'secondary' : undefined}>
        <InsertInvitationIcon />
      </FilledIconButton>
      {stage !== 'none' && (
        <DatePickerModal
          {...makeModalProps[stage]}
          open
          value={innerValue[stage]}
          onClose={resetModals}
          onChange={inner[stage]}
          disabled={disabled}
          minDate={stage === 'to' ? innerValue.from : undefined}
        />
      )}
    </>
  );
};

type DatePickerModalProps = {
  title: string;
  onClose: () => void;
  onChange: (date: Date) => void;
  open: boolean;
} & Omit<DateCalendarProps<Date>, 'showDaysOutsideCurrentMonth' | 'views' | 'view'>;

export default DateRangePickerModal;
