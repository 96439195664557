import { StatsParams } from 'api/controllers/typings';
import { useGetNominationsSentStats } from 'api/hooks/statistics/useGetNominationsSentStats';
import { useGetNominationsReceivedStats } from 'api/hooks/statistics/useGetNominationsReceivedStats';
import { useGetVotesCastStats } from 'api/hooks/statistics/useGetVotesCast';
import { useEffect, useMemo, useState } from 'react';
import { DateRange } from './DateRangePickerModal';

function useDebounce<T>(value: T, delay: number): [T, boolean] {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [isDebouncing, setIsDebouncing] = useState(false);

  useEffect(() => {
    setIsDebouncing(true);
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      setIsDebouncing(false);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return [debouncedValue, isDebouncing];
}

export function useReportData(timeframe: DateRange, limit: number) {
  const [params, isDebouncing] = useDebounce(
    useMemo<StatsParams>(
      () => ({
        from: timeframe.from,
        to: timeframe.to,
        limit,
      }),
      [timeframe, limit],
    ),
    100,
  );
  const { data: nominationsSent, status: nominationsSentStatus } = useGetNominationsSentStats(params);
  const { data: nominationsReceived, status: nominationsReceivedStatus } = useGetNominationsReceivedStats(params);
  const { data: votesCast, status: isVotesCastStatus } = useGetVotesCastStats(params);
  const areAllSuccess =
    nominationsSentStatus === 'success' && nominationsReceivedStatus === 'success' && isVotesCastStatus === 'success';
  const isAnyLoading =
    nominationsSentStatus === 'loading' || nominationsReceivedStatus === 'loading' || isVotesCastStatus === 'loading';
  return {
    disabled: !areAllSuccess,
    loading: isDebouncing || isAnyLoading,
    nominationsSent,
    nominationsReceived,
    votesCast,
  };
}
