import { Button } from '@mui/material';
import CelebrationIcon from '@mui/icons-material/Celebration';
import { useSnackbarMessages } from 'utils/snackbarMessages';
import { useGetWinners } from 'api/hooks/winners';
import { SectionWithTitleAndAction } from 'components/Layout/SectionWithTitleAndAction';
import LastMonthWinners from './LastMonthWinners';
import { PaginatedWinnersList } from './PaginatedWinnersList';

function getTitleText(isLoading: boolean, beeMonth: string | undefined) {
  if (isLoading) return 'Loading...';
  if (beeMonth) return `Bees of the ${beeMonth}!`;
  return 'No bees found :(';
}

export const WinnerList = () => {
  const { comingSoon } = useSnackbarMessages();
  const { data, isLoading } = useGetWinners();
  const beeMonth = data?.pages[0].winners[0].voting_id.split('-')[0];
  return (
    <SectionWithTitleAndAction
      title={getTitleText(isLoading, beeMonth)}
      action={
        <Button
          onClick={comingSoon}
          variant='outlined'
          color='contrast'
          sx={{ flexShrink: 0 }}
          startIcon={<CelebrationIcon />}>
          All Winners
        </Button>
      }>
      <LastMonthWinners />
      <PaginatedWinnersList />
    </SectionWithTitleAndAction>
  );
};
