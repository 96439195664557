import { Typography, styled, Skeleton } from '@mui/material';
import { createNOf } from 'components/helpers/createNOf';
import { NominationsSentStat } from 'api/controllers/typings';
import { withCutCorner } from '../Layout/cutCorner';
import { MostActiveBeeTitle } from './MostActiveBeeTitle';

type MostActiveBeesProps = {
  isLoading: boolean;
  topNominationsSenders?: NominationsSentStat[];
};

const MostActiveBeesContainer = withCutCorner(
  styled('div')(({ theme: { spacing, breakpoints } }) => ({
    cursor: 'default',
    padding: spacing(4),
    paddingBottom: spacing(2),
    boxSizing: 'border-box',
    '> div': {
      paddingBlock: spacing(2),
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'space-between',
      alignItems: 'center',
      [breakpoints.down(474)]: {
        overflowX: 'auto',
        flexDirection: 'row-reverse',
        '&, & > *': {
          transform: 'rotate(180deg)',
        },
      },
      gap: spacing(2.5),
    },
  })),
);

const MostActiveBees = ({ isLoading, topNominationsSenders }: MostActiveBeesProps) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <MostActiveBeesContainer cornerSize='20px' corner='TR'>
        {isLoading ? (
          <div>
            {createNOf(5, i => (
              <Skeleton key={i} width='150px' height='200px' variant='rounded' />
            ))}
          </div>
        ) : (
          <div>
            {topNominationsSenders !== undefined && topNominationsSenders.length > 0 ? (
              topNominationsSenders.map(topNominationsSender => (
                <MostActiveBeeTitle
                  email={topNominationsSender.nominator_email}
                  value={`${topNominationsSender.number_of_nominations}`}
                  key={topNominationsSender.nominator_email}
                />
              ))
            ) : (
              <Typography variant='headlineLarge'>Where are the busiest bees in our platform?</Typography>
            )}
          </div>
        )}
      </MostActiveBeesContainer>
    </div>
  );
};

export default MostActiveBees;
