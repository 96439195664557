import { FC } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import { CurrentPeriodOnly, PrivateRoute } from 'layouts/withRestriction';
import { NominatePage } from 'pages/private/Nominations/NominatePage';
import { StatisticsPage } from 'pages/private/Statistics/Page';
import { VotePage } from 'pages/private/VotePage';
import { HomePage } from 'pages/public/HomePage';
import { MainLayout } from 'layouts/mainLayout';
import Login from 'pages/public/Login';

export const Router: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route element={<PrivateRoute />}>
          <Route element={<MainLayout />}>
            <Route index element={<HomePage />} />
            <Route element={<CurrentPeriodOnly period='Nomination' />}>
              <Route path='/nominate' element={<NominatePage />} />
            </Route>
            <Route element={<CurrentPeriodOnly period='Voting' />}>
              <Route path='/vote' element={<VotePage />} />
            </Route>
            <Route path='/statistics' element={<StatisticsPage />} />
          </Route>
        </Route>
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </BrowserRouter>
  );
};
