import { Box, Skeleton, Typography } from '@mui/material';
import { PersistantTooltip } from 'components/PersistantTooltip/TopdownTooltip';
import { ColorWithText, getColorFromTheme } from 'theme/colorUtils';

export type StatProps = {
  data: number | string | undefined;
  color?: ColorWithText;
  tooltipText: string;
  key?: React.Key | null;
};

export const Statistic: React.FC<StatProps> = ({ data, color = 'text_primary', tooltipText }) => (
  <Box display='flex' flexDirection='column' gap='3px' alignItems='flex-start' mt='-5px'>
    <Typography variant='displayLarge' sx={{ color: getColorFromTheme(color) }}>
      {data === undefined ? <Skeleton /> : data}
    </Typography>
    <PersistantTooltip position='relative' arrow='left'>
      {tooltipText}
    </PersistantTooltip>
  </Box>
);
