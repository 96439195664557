import React from 'react';
import { Box, Typography, styled, Button } from '@mui/material';
import { withCutCorner } from '../../components/Layout/cutCorner';
import DashedLine from '../../assets/login_background.svg';
import SmallDashedLine from '../../assets/small_dashed_line.svg';
import Bee from '../../assets/a4bee_bee.svg';
import GoogleIcon from '../../assets/google.png';
import { ReactComponent as Logo } from '../../assets/a4bee_hexes.svg';
import { useAuth } from '../../providers/auth';

const LoginContainer = withCutCorner(
  styled('div')(({ theme: { palette, spacing } }) => ({
    background: palette.background.paperVariant,
    padding: spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'end',
    gap: spacing(1),
    minWidth: '21.25em',
    minHeight: '22em',
    zIndex: 1,
  })),
);

const LoginBackground = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  minHeight: '100vh',
  justifyContent: 'space-evenly',
  backgroundImage: `url(${DashedLine})`,
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  [theme.breakpoints.down('xs')]: {
    flexWrap: 'wrap',
    backgroundImage: `url(${SmallDashedLine})`,
    backgroundPosition: 'top right',
    backgroundSize: 'auto 100%',
    backgroundAttachment: 'local',
  },
}));

const LoginButton = styled(Button)(({ theme }) => ({
  borderRadius: '10px',
  background: theme.palette.background.paper,
  color: theme.palette.contrast.dark,
  fontFamily: 'Roboto',
  '&:hover': {
    backgroundColor: theme.palette.contrast.contrastText,
    boxShadow: '4px 4px 4px rgba(0.5, 0.5, 0.5, 0.5)',
  },
  boxShadow: '2px 2px 2px rgba(0.3, 0.3, 0.3, 0.3)',
  transition: 'box-shadow 0.3s ease-in-out',
}));

const Login: React.FC = () => {
  const {
    actions: { signIn },
  } = useAuth();

  return (
    <LoginBackground>
      <Box
        display='flex'
        flexDirection='column'
        color='primary.contrastText'
        sx={{
          '& > *': { m: { xxs: 3, xs: 2 } },
          maxWidth: { xxs: '21em', xs: '33.8125em' },
        }}>
        <Box>
          <Logo />
        </Box>
        <Box>
          <Typography variant='displayLarge'>Hello! Log in to Bee Of The Month Platform.</Typography>
        </Box>
        <Box>
          <Typography variant='headlineLarge'>It is time for voting :)</Typography>
        </Box>
      </Box>
      <Box sx={{ position: 'relative', m: '3em' }}>
        <img
          src={Bee}
          alt='Bee-logo'
          style={{
            position: 'absolute',
            top: '-2.5em',
            left: '1.5em',
            width: 'calc(20% + 20px)',
            height: 'calc(30% + 20px)',
            zIndex: 2,
          }}
        />
        <Box
          bgcolor='primary.main'
          style={{
            position: 'absolute',
            top: '13em',
            left: '18.8em',
            width: '2.5em',
            height: '1.5em',
            zIndex: 2,
          }}
        />
        <LoginContainer cornerSize='20px' corner='TR'>
          <Box>
            <Typography variant='titleLarge'>Log in to BOTM</Typography>
          </Box>
          <LoginButton variant='contained' onClick={() => signIn()}>
            <img src={GoogleIcon} alt='Google logo' style={{ width: '1.5em', height: '1.5em' }} />
            Continue with Google
          </LoginButton>
        </LoginContainer>
      </Box>
    </LoginBackground>
  );
};

export default Login;
