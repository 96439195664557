import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useReportData } from 'pages/private/Statistics/useReportData';
import { AVAILABLE_TIME_FRAMES } from 'pages/private/Statistics/SelectTimeframe';
import { NominationsSentStat } from 'api/controllers/typings';
import { SectionWithTitleAndAction } from 'components/Layout/SectionWithTitleAndAction';
import MostActiveBees from './MostActiveBees';

const LIMIT = 5;

function getTitleText(loading: boolean, nominationsSent?: NominationsSentStat[]) {
  if (loading) return 'Loading...';
  if (nominationsSent) return `Busiest bees in our platform!`;
  return 'No bees found :(';
}

export const MostActiveList = () => {
  const timeframe = AVAILABLE_TIME_FRAMES[4].value;
  const { loading, nominationsSent } = useReportData(timeframe, LIMIT);
  return (
    <SectionWithTitleAndAction
      useFlex
      title={getTitleText(loading, nominationsSent)}
      action={
        <Button
          to='/statistics'
          component={Link}
          variant='outlined'
          color='contrast'
          sx={{ flexShrink: 0 }}
          startIcon={<FormatListBulletedIcon />}>
          All Rankings
        </Button>
      }>
      <MostActiveBees isLoading={loading} topNominationsSenders={nominationsSent} />
    </SectionWithTitleAndAction>
  );
};
