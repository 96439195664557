import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import NominateIcon from '@mui/icons-material/VolunteerActivism';
import { NominationItem } from 'api/controllers/typings';
import { HTMLAttributes } from 'react';
import { Skeleton, styled } from '@mui/material';
import { UseInfiniteQueryResult } from 'react-query';
import { useUserData } from 'providers/user';
import { createNOf } from 'components/helpers/createNOf';
import { UserDataContextType } from 'providers/user/typings';
import bee from '../../assets/PRIDE_BEE_bg.png';
import { NominationTile, NominationTileProps } from './NominationTile';

export type NominationsListProps = {
  title: string;
  columns: number;
  nominations: NominationItem[];
  hasMore: boolean;
  loading?: boolean;
  fetchNext: UseInfiniteQueryResult['fetchNextPage'];
};

const mapNominationToTile: (
  n: NominationItem,
  getUserByEmail: UserDataContextType['getUserByEmail'],
) => NominationTileProps = (nomination, getUserByEmail) => {
  const {
    first_name: firstName,
    last_name: lastName,
    photo_data: avatarImage,
  } = getUserByEmail(nomination.nominee_email ?? '') ?? {};
  return {
    date: new Date(nomination.creation_date_millis),
    firstName,
    lastName,
    avatarImage: avatarImage ?? bee,
    value: nomination.value,
    comment: nomination.comment,
  };
};

type NominationsGridProps = HTMLAttributes<HTMLDivElement> & {
  columns?: number;
};

const TILE_HEIGHT = 48;
const GAP_HEIGHT = 3;
const GAP_NUM = GAP_HEIGHT * 8;

const NominationsGrid = styled(({ children, ...props }: NominationsGridProps) => <div {...props}>{children}</div>, {
  shouldForwardProp: propName => propName !== 'columns',
})(({ theme: { spacing }, columns }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
  gridTemplateRows: `repeat(3, 1fr)`,
  gap: `${spacing(GAP_HEIGHT)} 10px`,
  height: `${3 * TILE_HEIGHT + 2 * GAP_NUM}px`,
  overflowX: 'auto',
  overflowY: 'auto',
}));

export const NominationsList = ({
  title,
  columns,
  fetchNext,
  hasMore,
  nominations,
  loading = false,
}: NominationsListProps) => {
  const noNominations = !loading && (!nominations || nominations.length === 0);
  const { getUserByEmail } = useUserData();
  return (
    <div>
      <Box pb={2} display='flex' justifyContent='space-between'>
        <Typography variant='titleSmall'>{noNominations ? `No ${title}!` : title}</Typography>
        <Button
          onClick={() => fetchNext()}
          variant='outlined'
          color='contrast'
          disabled={!hasMore}
          startIcon={<NominateIcon />}>
          More
        </Button>
      </Box>
      <NominationsGrid columns={columns}>
        {loading || noNominations
          ? createNOf(3, index => (
              <Skeleton key={index} variant='rounded' height='48px' width='100%' animation={loading ? 'wave' : false} />
            ))
          : nominations.map(nomination => (
              <NominationTile key={nomination.id} {...mapNominationToTile(nomination, getUserByEmail)} />
            ))}
      </NominationsGrid>
    </div>
  );
};
