import { WinnerController } from 'api/controllers/WinnerController';
import { useQuery } from 'react-query';
import { WinnerResponse } from 'api/controllers/typings';
import { BasicQueryOptions } from '../typings';
import { GET_WINNERS } from '../queryKeys';

type UseGetWinnerProps = {
  id: string;
} & BasicQueryOptions<WinnerResponse>;

export const useGetWinner = ({ id, onSuccess, onError }: UseGetWinnerProps) => {
  const { data, ...rest } = useQuery({
    queryKey: [GET_WINNERS, id],
    queryFn: () => WinnerController.getWinner(id),
    onSuccess,
    onError,
  });
  return {
    winner: data,
    ...rest,
  };
};
