import { Avatar, Typography } from '@mui/material';
import GradientIcon from '@mui/icons-material/Gradient';
import { PersistantTooltip } from 'components/PersistantTooltip/TopdownTooltip';
import { FilledIconButton } from 'components/FilledIconButton';
import type { AvatarLayout, LayoutProps } from './typings';
import { HorizontalContainer, PersonBox, TokenTile } from './styled';

type LayoutDict = {
  [key in AvatarLayout]: (props: LayoutProps) => React.ReactElement;
};

function getAvatarMargin(avatarSize: number) {
  return ((1 - Math.sqrt(3) / 2) * avatarSize) / 2;
}

const HORIZONTAL_AVATAR_HEIGHT = 120;

const HorizontalLayout = ({ firstName, lastName, photoData, tooltipContent }: LayoutProps) => (
  <HorizontalContainer>
    <div style={{ position: 'relative' }}>
      <Avatar
        src={photoData}
        sx={{
          width: `${HORIZONTAL_AVATAR_HEIGHT}px`,
          height: `${HORIZONTAL_AVATAR_HEIGHT}px`,
          marginLeft: `-${getAvatarMargin(HORIZONTAL_AVATAR_HEIGHT)}px`,
        }}
        variant='hex'
      />{' '}
      <PersistantTooltip rotation={-90} arrow='left' variant='labelSmall' position={{ bottom: -1, left: '0.6rem' }}>
        {tooltipContent}
      </PersistantTooltip>
    </div>
    <Typography variant='titleMedium' component='p'>
      {firstName}
      <br />
      {lastName}
    </Typography>
  </HorizontalContainer>
);

const VerticalLayout = ({ firstName, lastName, photoData, tooltipContent }: LayoutProps) => (
  <div className='vertical-layout'>
    <Avatar src={photoData} className='avatar-container' variant='hex' />{' '}
    <div>
      <Typography variant='labelMedium' component='p' color='contrast.main'>
        {firstName}
        <br />
        {lastName}
      </Typography>
      <div className='info-container'>
        <PersistantTooltip arrow='left' variant='labelSmall' position='nomargin'>
          {tooltipContent}
        </PersistantTooltip>
      </div>
    </div>
  </div>
);

const NFTImageLayout = ({ firstName, lastName, photoData, nftButtonAction, tooltipContent, image }: LayoutProps) => (
  <TokenTile img={image}>
    <PersonBox>
      <Avatar src={photoData} sx={{ width: '50px', height: '50px' }} variant='hex' />
      <Typography variant='titleSmall' component='p'>
        {firstName}
        <br />
        {lastName}
      </Typography>
    </PersonBox>
    <FilledIconButton onClick={nftButtonAction} title='Open modal'>
      <GradientIcon />
    </FilledIconButton>
    <PersistantTooltip rotation={-90} arrow='left' variant='labelSmall' position={{ bottom: '60px', left: 4 }}>
      {tooltipContent}
    </PersistantTooltip>
  </TokenTile>
);

export const layoutDict: LayoutDict = {
  horizontal: HorizontalLayout,
  vertical: VerticalLayout,
  NFTImage: NFTImageLayout,
};
