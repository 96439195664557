import { StatisticsController } from 'api/controllers/StatisticsController';
import { StatsParams } from 'api/controllers/typings';
import { useQuery } from 'react-query';
import { GET_NOMINATIONS_RECEIVED_STATS } from '../queryKeys';

export function useGetNominationsReceivedStats(params: StatsParams) {
  return useQuery([GET_NOMINATIONS_RECEIVED_STATS, params], () =>
    StatisticsController.getNominationsReceivedStats(params),
  );
}
