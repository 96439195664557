import { CounterWithIcon, CounterWithIconProps } from 'components/CounterWithIcon';
import { Box, Container, Typography, ListItemText } from '@mui/material';
import { OrderedList } from 'components/Layout/OrderedList';
import React from 'react';
import { GradientSection } from './Layout/GradientSection';

export type CounterInfo = CounterWithIconProps;

export type CTAProps = {
  counters: Array<CounterInfo>;
  title: React.ReactNode;
  points?: Array<React.ReactNode | string>;
  columns: React.CSSProperties['gridTemplateColumns'];
  gap?: React.CSSProperties['gap'];
};

export const CallToAction = ({ counters, title, points, columns, gap }: CTAProps) => (
  <GradientSection>
    <Container
      sx={{
        py: 7.5,
        display: { xxs: 'flex', md: 'grid' },
        gridTemplateColumns: columns,
        gap,
        flexDirection: 'column',
      }}>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='flex-start'
        gap='0.5em'
        position='relative'>
        {counters.map((counterProps, index) => (
          <CounterWithIcon key={index} {...counterProps} />
        ))}
      </Box>
      <Box>
        {title}
        {points && (
          <Typography component='div' variant='bodyLarge'>
            <OrderedList>
              {points.map((point, index) => (
                <ListItemText key={index}>{point}</ListItemText>
              ))}
            </OrderedList>
          </Typography>
        )}
      </Box>
    </Container>
  </GradientSection>
);
