import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { styled, capitalize } from '@mui/material';
import { format } from 'date-fns';
import { PersistantTooltip } from 'components/PersistantTooltip/TopdownTooltip';
import { neutralVariant } from 'theme/colors';
import { useState } from 'react';
import { NominationDialog } from './NominationDialog';

export type NominationTileProps = {
  firstName?: string;
  lastName?: string;
  avatarImage?: string;
  date: Date;
  value?: string;
  comment?: string;
};

export const DateTypography = styled(Typography)({
  color: neutralVariant[50],
  fontFamily: 'Montserrat',
  fontSize: '11px',
  fontWeight: '500',
  lineHeight: '16px',
  letterSpacing: '0.5px',
  textAlign: 'left',
});

export const NominationTile = (props: NominationTileProps) => {
  const { firstName, lastName, avatarImage, date, value } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleClickOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Box
        p='4px 15px 4px 7px'
        bgcolor='background.default'
        display='flex'
        gap='6px'
        justifyContent='space-between'
        alignItems='center'
        minWidth='240px'
        sx={{ cursor: 'pointer' }}
        onClick={handleClickOpen}>
        <Box display='flex' justifyContent='space-between' alignItems='center' gap='10px'>
          <Avatar src={avatarImage} variant='hex' sx={{ height: '40px', width: '40px' }} />
          <Box display='flex' flexDirection='column'>
            {firstName && lastName && (
              <Typography variant='labelSmall'>
                {capitalize(firstName)} {lastName[0].toLocaleUpperCase()}.
              </Typography>
            )}
            <DateTypography>{format(date, 'dd.MM.yy')}</DateTypography>
          </Box>
        </Box>
        <PersistantTooltip variant='labelSmall' arrow='left' position='relative'>
          {value}
        </PersistantTooltip>
      </Box>
      {isDialogOpen && <NominationDialog open={isDialogOpen} close={handleClose} {...props} />}
    </>
  );
};
