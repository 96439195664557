import { containerClasses, Container, Typography, styled } from '@mui/material';
import { ReactNode } from 'react';
import { gradientSectionStyles } from './GradientSection';
import { withCutCorner } from './cutCorner';

type SectionWithTitleAndActionProps = {
  title: ReactNode;
  action: ReactNode;
  children: ReactNode;
  useFlex?: boolean;
};

const SectionContainer = withCutCorner(
  styled('div')<Pick<SectionWithTitleAndActionProps, 'useFlex'>>(({ theme, useFlex = false }) => ({
    ...gradientSectionStyles({ theme }),
    background: theme.palette.gradients.bottom({ startOpacity: 50 }),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(6),
    [`& .${containerClasses.root}`]: {
      ...(useFlex
        ? {
            display: 'flex',
            flexDirection: 'column',
          }
        : {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
            gap: theme.spacing(3),
          }),
      [theme.breakpoints.down(1438)]: {
        display: 'flex',
        flexDirection: 'column',
        '& .paginated--container': {
          width: 'calc(50% + 50vw)',
        },
      },
    },
    '& h2': {
      color: theme.palette.neutralVariant[theme.palette.mode === 'dark' ? 50 : 60],
      marginBottom: '6px',
    },
    '& .headlineClass': {
      cursor: 'default',
      '& > button': {
        marginLeft: 'auto',
        display: 'flex',
      },
      [theme.breakpoints.up(400)]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        '& > button': {
          marginLeft: 'none',
        },
      },
      gridColumn: 'span 2',
    },
  })),
);

export const SectionWithTitleAndAction = ({ title, action, children, useFlex }: SectionWithTitleAndActionProps) => {
  return (
    <SectionContainer useFlex={useFlex}>
      <Container>
        <div className='headlineClass'>
          <Typography variant='headlineLarge' component='h2'>
            {title}
          </Typography>
          {action}
        </div>
        {children}
      </Container>
    </SectionContainer>
  );
};
