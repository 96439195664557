import { useUserData } from 'providers/user';
import { Avatar, Typography, capitalize } from '@mui/material';
import bee from 'assets/PRIDE_BEE_bg.png';
import { getFirstLastName } from 'utils/getInitials';
import { PersistantTooltip } from 'components/PersistantTooltip/TopdownTooltip';

type MostActiveBeeTitleProps = {
  email: string;
  value?: string;
};

export const MostActiveBeeTitle = ({ email, value }: MostActiveBeeTitleProps) => {
  const { getUserByEmail } = useUserData();
  const mostActiveBee = getUserByEmail(email);
  const fromEmailName = getFirstLastName(email);
  const firstName = capitalize(mostActiveBee?.first_name ?? fromEmailName[0] ?? 'awesome');
  const lastName = capitalize(mostActiveBee?.last_name ?? fromEmailName[1] ?? 'bee');
  return (
    <div className='active-bees-layout'>
      <Avatar src={mostActiveBee?.photo_data ?? bee} className='avatar-container' variant='hex' />{' '}
      <div className='details-container'>
        <Typography variant='labelMedium' component='p' color='contrast.main'>
          {firstName}
          <br />
          {lastName}
        </Typography>
        <div>
          <PersistantTooltip arrow='left' variant='labelSmall' position='nomargin'>
            {value} votes
          </PersistantTooltip>
        </div>
      </div>
    </div>
  );
};
